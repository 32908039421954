import React, { FormEvent, useContext } from "react"
import { PageContext } from "../../../context/PageContext"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import Button from "../../atoms/Button"
import LoadingSpinner from "../../atoms/LoadingSpinner"
import { FormCommonProps } from "../../dato/blocks/DatoForm"
import * as styles from "./ContactProductSupportBasin.module.scss"
import ConsentField from "./subcomponents/ConsentField"
import ProductSupportCategoryRadioButtons from "./subcomponents/ProductSupportCategoryRadioButtons"
import ProductSupportComplaintFields from "./subcomponents/ProductSupportComplaintFields"
import ProductSupportFeedbackFields from "./subcomponents/ProductSupportFeedbackFields"
import ProductSupportSparePartsFields from "./subcomponents/ProductSupportSparePartsFields"
import ProductSupportSupportFields from "./subcomponents/ProductSupportSupportFields"

type ContactProductSupportBasinProps = FormCommonProps

enum RequestStatus {
  /** Request is initialized but not invoked yet. Initial state. */
  EMPTY = "Empty",
  /** Request is started and waiting for response. */
  LOADING = "Loading",
  /** Request has ended in error. Error message is available. */
  ERROR = "Error",
  /** Request ended successfully and response data is available. */
  SUCCESS = "Success",
}

const ContactProductSupportBasin = (props: ContactProductSupportBasinProps) => {
  // console.log('Rendering ContactProductSupportBasin', props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("formCommon", locale)
  const [status, setStatus] = React.useState<RequestStatus>(RequestStatus.EMPTY)
  const [category, setCategory] = React.useState<string>("productFeedback")
  const form = React.useRef<HTMLFormElement>(null)
  const [files, setFiles] = React.useState<File[]>([])

  const getEndpoint = (category?: string) => {
    // category options
    // const optValues = ["productFeedback", "productComplaint", "support", "spareParts"]
    switch (category) {
      case "productFeedback":
        return "https://usebasin.com/f/a0f386f8a35c"

      case "productComplaint":
        return "https://usebasin.com/f/b973320258a7"

      case "spareParts":
        return "https://usebasin.com/f/32dc646c63b1"

      case "support":
      default:
        return "https://usebasin.com/f/d7082b6faa1e"
    }
  }

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (form.current?.checkValidity()) {
      setStatus(RequestStatus.LOADING)

      const ep = getEndpoint(category)
      const endPointJson = ep + ".json"
      const data = new FormData(form.current)

      for (let i = 0; i < files.length; i++) {
        data.append("attachment_" + i, files[i])
      }

      data.append("locale", locale)
      // used to indicate different websites before they were combined to "global" www.harvia.com
      data.append("website", "global")

      fetch(endPointJson, { method: "post", headers: { Accept: "application/json" }, body: data })
        .then(() => {
          setStatus(RequestStatus.SUCCESS)
        })
        .catch(e => {
          console.error("Error", e)
          setStatus(RequestStatus.ERROR)
        })
    }
  }

  return (
    <>
      {(status === RequestStatus.EMPTY || status === RequestStatus.LOADING) && (
        <form ref={form} className={styles.container} onSubmit={submit}>
          <ProductSupportCategoryRadioButtons
            initialCategory={category}
            locale={locale}
            onChange={e => {
              setCategory(e.currentTarget.value)
              setFiles([])
            }}
          />

          {category === "productFeedback" && <ProductSupportFeedbackFields onFilesChange={setFiles} />}
          {category === "productComplaint" && <ProductSupportComplaintFields onFilesChange={setFiles} />}
          {category === "spareParts" && <ProductSupportSparePartsFields onFilesChange={setFiles} />}
          {category === "support" && <ProductSupportSupportFields onFilesChange={setFiles} />}

          {category && (
            <>
              <ConsentField name="consent-pii-data" />

              <Button
                label={t("sendButton.label", "Approve & send")}
                type="submit"
                disabled={status === RequestStatus.LOADING}
              />
            </>
          )}

          <LoadingSpinner loading={status === RequestStatus.LOADING} />
        </form>
      )}

      {status === RequestStatus.SUCCESS && (
        <div className={styles.container}>{props.successMessage || <p>Thank you!</p>}</div>
      )}

      {status === RequestStatus.ERROR && (
        <div className={styles.container}>{props.errorMessage || <p>Error occured! Please try again.</p>}</div>
      )}
    </>
  )
}

export default ContactProductSupportBasin
