import React from "react"

type HoneypotFieldProps = {
  /** Honeypot field name defined in Basin */
  name: string
  /** "Realistic" label for the field */
  label: string
}

/** Hidden spam protection honeypot field */
const HoneypotField = (props: HoneypotFieldProps) => {
  // console.log('Rendering HoneypotField', props)
  return (
    <div style={{ position: "absolute", width: 1, height: 1, overflow: "hidden" }} aria-hidden={true}>
      <label htmlFor={props.name}>{props.label}</label>
      <input type="text" name={props.name} required={false} autoComplete="off" tabIndex={-1} />
    </div>
  )
}

export default HoneypotField
