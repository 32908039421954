import React, { ReactNode } from "react"
import Label, { LabelProps } from "./Label"
import * as styles from "./TextArea.module.scss"

export type TextAreaProps = {
  name: string
  /** Unique value when form requires multiple inputs with same `name`. */
  id?: string
  placeholder?: string
  errorMsg?: string
  defaultValue?: string
  optionalText?: string
  helpText?: string | ReactNode
  // labelAttrs?: React.LabelHTMLAttributes<HTMLLabelElement>
  /** HTML attributes for `<textarea>` element. */
  textareaAttrs?: Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "name" | "id" | "placeholder" | "required">
} & Omit<LabelProps, "for">

const TextArea = (props: TextAreaProps) => {
  const id = props.id || props.name
  const errorId = `${id}-err`
  const { required = true } = props

  const inputClassNames = [styles.textarea, props.errorMsg ? styles.invalid : styles.valid].join(" ")

  return (
    <div className={styles.container}>
      <Label
        for={id}
        label={props.label}
        optionalText={props.optionalText}
        required={required}
        helpText={props.helpText}
      />

      <textarea
        {...props.textareaAttrs}
        name={props.name}
        id={id}
        placeholder={props?.placeholder || " "}
        className={inputClassNames}
        aria-describedby={errorId}
        required={required}
        defaultValue={props.defaultValue}
      />
      <label className={styles.error} id={errorId}>
        {props.errorMsg}
      </label>
    </div>
  )
}

export default TextArea
