import React from "react"
import * as styles from "./RadioButton.module.scss"

type RadioButtonProps = {
  name: string
  label: string | number
  value: string | number
  required?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const RadioButton = (props: RadioButtonProps) => {
  // console.log('Rendering RadioButton', props)
  const { label, ...rest } = props
  return (
    <label className={styles.container}>
      <input type="radio" className={styles.input} {...rest} />
      <span className={styles.customInput}></span>
      <span className={styles.label}>{label}</span>
    </label>
  )
}

export default RadioButton
