import React from "react"
import Label, { LabelProps } from "./Label"
import Option, { OptionProps } from "./Option"
import OptionGroup, { OptionGroupProps } from "./OptionGroup"
import * as styles from "./Select.module.scss"

// TODO:
// - esivalittu/esivalitut optiot propsina

export type SelectProps = {
  name: string
  // labelAttrs?: React.LabelHTMLAttributes<HTMLLabelElement>
  options: OptionProps[] | OptionGroupProps[]
  /** Unique value when form requires multiple inputs with same `name` */
  id?: string
  /** Error message describing why value is not passing validation. Implies input is in error state. */
  errorMsg?: string
  /** Other attributes passed to `<select>` element. */
  attrs?: Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "name" | "id" | "required">
} & Omit<LabelProps, "for">

export function mapToOptions<T extends string>(values?: { [key in T]: string | number }): OptionProps<T>[] {
  if (!values) {
    return []
  }
  return Object.entries<string | number>(values).map(([key, val]) => {
    return { label: val, value: key as T }
  })
}

/**
 * Dropdown widget
 */
const Select: React.FC<SelectProps> = props => {
  const id = props.id || props.name
  const errorId = `${id}-err`
  const { required = true } = props
  return (
    <div className={styles.container}>
      <Label
        for={id}
        label={props.label}
        optionalText={props.optionalText}
        required={required}
        helpText={props.helpText}
      />
      <div className={styles.wrapper}>
        <select
          {...props.attrs}
          id={id}
          name={props.name}
          className={styles.select}
          aria-label={props.label}
          aria-describedby={errorId}
          required={required}>
          {props.options.map((option: OptionProps | OptionGroupProps) => {
            if ("options" in option) {
              return <OptionGroup key={option.label} {...option} />
            }
            return <Option key={option.label} {...option} />
          })}
        </select>
      </div>
      <label className={styles.error} id={errorId}>
        {props.errorMsg}
      </label>
    </div>
  )
}

export default Select
