import React from "react"
import Button from "../../atoms/Button"
import * as styles from "./FileInputUploadPreview.module.scss"

type FileInputUploadPreviewProps = {
  /** Title for file list */
  title: string
  /** Files to list */
  files: File[]
  /** Callback called with file name when user clicks remove button */
  onRemoveClick: (fileName: string) => void
  /** Label on remove button */
  removeLabel: string
  isValidFile: (file: File) => boolean
}

/**
 * Shows a preview of files to be uploaded with buttons to remove a file.
 */
const FileInputUploadPreview: React.FC<FileInputUploadPreviewProps> = props => {
  if (!props.files || props.files.length < 1) {
    return null
  }

  return (
    <>
      <p>{props.title}</p>
      <ul className={styles.list}>
        {props.files.map(file => {
          // const types = file.type.split("/")
          // const isImage = types[0] === "image"
          return (
            <li key={file.name} className={styles.item} data-valid={props.isValidFile(file)}>
              <span>{file.name}</span> {/*<span>{isImage ? "image" : types[1]}</span>*/}
              <Button
                size="small"
                label={props.removeLabel || "Remove"}
                onClick={() => props.onRemoveClick(file.name)}
              />
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default FileInputUploadPreview
