import React, { ReactNode, useContext } from "react"
import { PageContext } from "../../../../context/PageContext"
import { CrmFieldErrors, fieldError } from "../../../../hooks/useCrmApi"
import useDatoMicrocopy from "../../../../hooks/useDatoMicrocopy"
import Fieldset from "../../../atoms/form/Fieldset"
import Input from "../../../atoms/form/Input"

type UserContactFieldsetProps = {
  errors?: CrmFieldErrors
  /** Additional fields (or other content) added to fieldset after the default form fields */
  children?: ReactNode
}

/**
 * Basic Contact information fields shared by many forms.
 */
const UserContactFieldset = (props: UserContactFieldsetProps) => {
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("formContact", locale)

  const getErrorMsg = (field: string): string | undefined => {
    const error = fieldError(props.errors, field)
    return error?.message
  }

  return (
    <Fieldset legend={t("legend.contactInfo", "Your contact information")}>
      <Input
        name="firstName"
        label={t("firstName.label", "First name")}
        required={true}
        inputAttrs={{ maxLength: 40 }}
        errorMsg={getErrorMsg("firstName")}
      />
      <Input
        name="lastName"
        label={t("lastName.label", "Last name")}
        required={true}
        inputAttrs={{ maxLength: 80 }}
        errorMsg={getErrorMsg("lastName")}
      />
      <Input
        name="email"
        label={t("email.label", "Email")}
        required={true}
        placeholder={"user@example.com"}
        inputAttrs={{ type: "email", maxLength: 100 }}
        errorMsg={getErrorMsg("email")}
      />
      <Input
        name="phone"
        label={t("phone.label", "phone")}
        optionalText={t("optional", "optional", "formCommon")}
        required={false}
        inputAttrs={{ maxLength: 50 }}
        errorMsg={getErrorMsg("phone")}
      />
      <Input
        name="streetAddress"
        label={t("streetAddress.label", "Street address")}
        optionalText={t("optional", "optional", "formCommon")}
        required={false}
        errorMsg={getErrorMsg("streetAddress")}
      />
      <Input
        name="zipCode"
        label={t("zipCode.label", "Zip code")}
        optionalText={t("optional", "optional", "formCommon")}
        required={false}
        inputAttrs={{ maxLength: 250 }}
        errorMsg={getErrorMsg("zipCode")}
      />
      <Input
        name="city"
        label={t("city.label", "City")}
        optionalText={t("optional", "optional", "formCommon")}
        required={false}
        inputAttrs={{ maxLength: 40 }}
        errorMsg={getErrorMsg("city")}
      />
      {props.children}
    </Fieldset>
  )
}

export default UserContactFieldset
