import React, { useContext } from "react"
import Checkbox, { CheckboxProps } from "../../../../components/atoms/form/Checkbox"
import { PageContext } from "../../../../context/PageContext"
import useDatoMicrocopy from "../../../../hooks/useDatoMicrocopy"
// import * as styles from "./ConsentField.module.scss"

type ConsentFieldProps = Partial<CheckboxProps>

const ConsentField = (props: ConsentFieldProps) => {
  // console.log('Rendering ConsentField', props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("formCommon", locale)
  return (
    <Checkbox
      name="consent-pii-data"
      label={
        <span>
          {t("consent-pii-data.label", "I agree to the storage and processing of my personal data (required).")}{" "}
          <a href={t("consent-pii-data.labelUrlPath", "/en/register-description")} target="_blank">
            {t("consent-pii-data.labelUrlText", "Register description")}
          </a>
        </span>
      }
      required={true}
      {...props}
    />
  )
}

export default ConsentField
