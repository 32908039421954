import React from "react"
import * as styles from "./Input.module.scss"
import Label, { LabelProps } from "./Label"

export type InputProps = {
  /** Value for `<input>` element `name` attribute. */
  name: string
  /** Unique value when form requires multiple inputs with same `name`. */
  id?: string
  placeholder?: string
  /** Error message describing why value is not passing validation. Implies input is in error state. */
  errorMsg?: string

  /**
   * Added to label if field is optional
   * @default "optional"
   */
  optionalText?: string

  // labelAttrs?: React.LabelHTMLAttributes<HTMLLabelElement>
  /** Attributes passed to `<input>` element. */
  inputAttrs?: Omit<React.InputHTMLAttributes<HTMLInputElement>, "name" | "id" | "placeholder" | "required">
} & Omit<LabelProps, "for">

const Input: React.FC<InputProps> = props => {
  const id = props.id || props.name
  const errorId = `${id}-err`
  const { required = true } = props

  const inputClassNames = [styles.input, props.errorMsg ? styles.invalid : styles.valid].join(" ")

  return (
    <div className={styles.container}>
      <Label
        for={id}
        label={props.label}
        optionalText={props.optionalText}
        required={required}
        helpText={props.helpText}
      />
      <input
        {...props.inputAttrs}
        className={inputClassNames}
        name={props.name}
        id={id}
        placeholder={props.placeholder || " "}
        required={required}
        aria-describedby={errorId}
      />

      <label className={styles.error} id={errorId}>
        {props.errorMsg}
      </label>
    </div>
  )
}

export default Input
