import React, { useContext, useState } from "react"
import HoneypotField from "../../../../components/atoms/form/HoneypotField"
import { OptionProps } from "../../../../components/atoms/form/Option"
import Select from "../../../../components/atoms/form/Select"
import FileInput from "../../../../components/molecules/form/FileInput"
import { PageContext } from "../../../../context/PageContext"
import useDatoMicrocopy from "../../../../hooks/useDatoMicrocopy"
import Fieldset from "../../../atoms/form/Fieldset"
import Input from "../../../atoms/form/Input"
import TextArea from "../../../atoms/form/TextArea"
import UserContactFieldset from "./UserContactFieldset"
// import * as styles from "./ProductSupportFeedbackFields.module.scss"

type ProductSupportFeedbackFieldsProps = { onFilesChange: (files: File[]) => void }

const ProductSupportFeedbackFields = (props: ProductSupportFeedbackFieldsProps) => {
  // console.log('Rendering ProductSupportFeedbackFields', props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("formContact", locale)
  const productTypes: OptionProps<string>[] = [
    { value: "electric_heater", label: t("productCategory.electricHeater", "Electric heater") },
    { value: "wood_heater", label: t("productCategory.woodHeater", "Wood burning heater") },
    { value: "steam_generator", label: t("productCategory.steamGenerator", "Steam generator") },
    { value: "control_panel", label: t("productCategory.controlPanel", "Control panel") },
    { value: "infrared", label: t("productCategory.infrared", "Infrared heater") },
    { value: "accessory", label: t("productCategory.accessory", "Accessory") },
    { value: "sauna_cabin", label: t("productCategory.saunaCabin", "Sauna cabin") },
    { value: "indoor_sauna", label: t("productCategory.indoorSauna", "Indoor sauna") },
    { value: "hot_tub", label: t("productCategory.hotTub", "Hot tub") },
    { value: "other", label: t("productCategory.other", "Other") },
  ]

  const [productType, setProductType] = useState(productTypes[0].value)

  return (
    <>
      <HoneypotField name="piilo-info" label="Info" />
      <Fieldset legend={t("productComplaint", "", "productSupportCategorySelect")}>
        <Input
          name="productModel"
          label={t("productType.label", "Product make and type")}
          helpText={
            <a href={t("productType.helpUrl", "/en/contact/where-to-find-harvia-serial-number")} target="_blank">
              {t("productType.helpText", "How can I tell the model of my heater or stove?")}
            </a>
          }
          //errorMsg={getErrorMsg("productModel")}
        />

        <Input
          name="productRegistrationLocation"
          label={t("productRegistrationLocation.label", "Place of purchase or street address of the new property")}
          helpText={t("productRegistrationLocation.helpText", "")}
          //errorMsg={getErrorMsg("productRegistrationLocation")}
        />
        <Input
          name="productInstallDate"
          inputAttrs={{ type: "date", pattern: "d{4}-d{2}-d{2}" }}
          label={t("productInstallDate.label", "Date of purchase or delivery")}
          helpText={t("productInstallDate.helpText", "")}
          //errorMsg={getErrorMsg("productInstallDate")}
        />
        <Select
          name="productCategory"
          label={t("productCategory.label", "Product category")}
          options={productTypes}
          attrs={{
            onChange: e => {
              setProductType(e.currentTarget.value)
            },
          }}
          optionalText={t("optional", "optional", "formCommon")}
        />
        <Input
          name="productSerial"
          label={t("productSerial.label", "Product serial number")}
          helpText={
            <a href={t("productSerial.helpUrl", "/en/contact/where-to-find-harvia-serial-number")} target="_blank">
              {t("productSerial.helpText", "Where to find serial numbers on Harvia products")}
            </a>
          }
          optionalText={t("optional", "optional", "formCommon")}
          required={["electric_heater", "wood_heater", "steam_generator", "control_panel"].includes(productType)}
          // errorMsg={getErrorMsg("productSerial")}
        />
      </Fieldset>

      <Fieldset legend={t("message.label", "Your message", "formCommon")}>
        <Input
          name="topic"
          label={t("legend.topic", "Topic")}
          required
          //errorMsg={getErrorMsg("productModel")}
        />
        <TextArea
          name="message"
          label={t("message.label", "Your message", "formCommon")}
          optionalText={t("optional", "optional", "formCommon")}
          required={false}
        />
        <FileInput
          name="files"
          label={t("files.label", "Drag &amp; Drop your files here", "formCommon")}
          buttonLabel="Select files"
          onFilesChange={props.onFilesChange}
          previewTitle={t("files.preview.title", "Selected files", "formCommon")}
          previewRemoveLabel={t("files.preview.buttonLabel", "Remove", "formCommon")}
          optionalText={t("optional", "optional", "formCommon")}
          required={false}
          inputAttrs={{ accept: "image/*" }}
        />
      </Fieldset>

      <UserContactFieldset />
    </>
  )
}

export default ProductSupportFeedbackFields
