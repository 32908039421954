import React from "react"
import * as styles from "./Fieldset.module.scss"

export type FieldsetProps = {
  /** content or children for `<legend>` element */
  legend: React.ReactNode
  description?: string
  attrs?: React.FieldsetHTMLAttributes<HTMLFieldSetElement>
  children: React.ReactNode
}

/**
 * Form fieldset
 */
const Fieldset: React.FC<FieldsetProps> = props => {
  return (
    <fieldset {...props.attrs} className={styles.fieldset}>
      <legend className={styles.legend}>{props.legend}</legend>
      {props.description && <p>{props.description}</p>}
      {props.children}
    </fieldset>
  )
}

export default Fieldset
