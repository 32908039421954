import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import Button from "../../atoms/Button"
import Label, { LabelProps } from "../../atoms/form/Label"
import * as styles from "./FileInput.module.scss"
import FileInputUploadPreview from "./FileInputUploadPreview"

export type FileInputProps = {
  /** Form input name */
  name: string
  /** Text informing of drag&drop functionality. Shown next to File browser button. */
  text?: string
  optionalText?: string
  /** Label for File chooser button. Opens native File browser to select file(s) */
  buttonLabel: string
  onFilesChange: (files: File[]) => void
  validationErrorMsgs?: Record<FileInputValidationError, string>
  previewTitle: string
  previewRemoveLabel: string
  /** Unique value when form requires multiple inputs with same `name` */
  id?: string
  errorMsg?: string
  /** Maximun files size allowed in bytes */
  maxSize?: number
  /** Maximum count of files allowed in total. Defaults to 4 */
  maxFileCount?: number
  inputAttrs?: Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "id" | "value" | "title">
} & Omit<LabelProps, "for">

/**
 * Validation error code
 */
export type FileInputValidationError = "maxCount"

/**
 * Object holding user input File objects.
 * We use an object instead of straight FileList for easier manipulation of state
 * and to prevent files with the same name from being uploaded more than once.
 */
type FilesState = { [fileName: string]: File }

const FileInput: React.FC<FileInputProps> = props => {
  // console.debug("Rendering FileInput", props)
  // create ref so we can open native file explorer from a button or other elements as well as input element itself
  const fileInputRef = useRef(null)

  const [files, setFiles] = useState<FilesState>({})
  const [error, setError] = useState<FileInputValidationError>()

  const id = props.id || props.name
  const errorId = `${id}-err`

  const { required = true, maxSize, maxFileCount = 4 } = props
  const { multiple = true } = props.inputAttrs || {}

  // pass valid files back to parent as array of File objects on files change
  useEffect(() => {
    const newFiles = Object.values(files)
    props.onFilesChange(newFiles.filter(isValidFile))
    if (newFiles.length <= maxFileCount && error === "maxCount") {
      setError(undefined)
    }
  }, [files])

  const isValidFile = (file: File): boolean => {
    return maxSize && file.size > maxSize ? false : true
  }

  const getValidationErrorMsg = (code: FileInputValidationError): string => {
    // TODO tee järkevämpi systeemi lokalisoitujen sisäisten virheviestien välittämiseen
    // Sisäinen virheviesti voisi myös näkyä suljettavassa notifikaatiodialogissa mutta sellaista ei ole suunniteltu UX-puolelta
    // Nyt viesti jää nälyviin ja esim liian monta tiedostoa lisättäessä viesti näkyy vaikka valittuna on sallittu määrä
    const messages = {
      maxCount: "Too many files",
      ...props.validationErrorMsgs,
    }
    return messages[code] || "Invalid input"
  }

  /**
   * Add File objects to `files` state
   */
  const addFile = (inputFiles: FileList): FilesState => {
    let newState: FilesState
    if (multiple) {
      const currentFiles = Object.values(files)
      if (currentFiles.length + inputFiles.length > maxFileCount) {
        setError("maxCount")
        return files
      } else {
        newState = Object.assign({}, files) // clone current state
        Array.from(inputFiles).forEach(file => {
          newState[file.name] = file
        })
      }
    } else {
      newState = {}
      const file = inputFiles.item(0)
      if (file) {
        newState[file.name] = file
      }
    }
    setError(undefined)
    return newState
  }

  /**
   * Remove a file from `files` state by filename.
   */
  const removeFile = (fileName: string) => {
    const newState = Object.assign({}, files) // clone current state
    delete newState[fileName]
    setFiles(newState) // update state, triggers re-render and props.onFilesChange() callback
  }

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (files && files.length) {
      const newState = addFile(files)
      setFiles(newState) // update state, triggers re-render and props.onFilesChange() callback
    }
  }

  return (
    <div className={styles.container}>
      <Label
        for={id}
        label={props.label}
        optionalText={props.optionalText}
        required={required}
        helpText={props.helpText}
      />
      <div className={styles.inputContainer}>
        {props.text && <p className={styles.text}>{props.text}</p>}
        <Button label={props.buttonLabel} size="flat" tabIndex={-1} />
        <input
          {...props.inputAttrs}
          ref={fileInputRef}
          type="file"
          onChange={onFileChange}
          id={id}
          className={styles.input}
          title="" // remove native tooltip on hover i.e. "Choose files..."
          value="" // set to empty so that DOM state resets on every re-render e.g. state change. Needed because onchange event doesn't fire if the user select the same file again and we allow also removing of files
          multiple={multiple}
          aria-describedby={errorId}
        />
      </div>

      <FileInputUploadPreview
        files={Object.values(files)}
        onRemoveClick={removeFile}
        title={props.previewTitle}
        removeLabel={props.previewRemoveLabel}
        isValidFile={isValidFile}
      />
      <label className={styles.error} id={errorId}>
        {props.errorMsg}
        {error && <p>{getValidationErrorMsg(error)}</p>}
      </label>
    </div>
  )
}

export default FileInput
