import React from "react"
import Option, { OptionProps } from "./Option"
// import * as styles from "./Option.module.scss"

export type OptionGroupProps = {
  label: string | number
  options: OptionProps[]
}

const OptionGroup: React.FC<OptionGroupProps> = props => {
  return (
    <optgroup label={props.label.toString()}>
      {props.options.map(option => (
        <Option key={option.label} {...option} />
      ))}
    </optgroup>
  )
}

export default OptionGroup
