import React from "react"
// import * as styles from "./Option.module.scss"

export type OptionProps<T = string | number> = {
  label: string | number
  value: T
}

const Option: React.FC<OptionProps> = props => {
  return <option value={props.value}>{props.label}</option>
}

export default Option
