import React, { ReactNode } from "react"
import InfoTooltip from "../../molecules/InfoTooltip"
import * as styles from "./Label.module.scss"

export type LabelProps = {
  for: string
  label: string
  /**
   * Indicate is the form element required
   * @default true
   */
  required?: boolean
  /**
   * Added to label if field is optional
   * @default "optional"
   */
  optionalText?: string
  helpText?: string | ReactNode
}

const Label = (props: LabelProps) => {
  const { required = true, optionalText = "optional" } = props
  return (
    <label className={styles.label} htmlFor={props.for}>
      {props.label} {required === false ? <span className={styles.optional}>{optionalText}</span> : ""}
      {props.helpText && (
        <div /*className={styles.tooltip}*/>
          <InfoTooltip text={props.helpText} />
        </div>
      )}
    </label>
  )
}

export default Label
