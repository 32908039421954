import React, { useContext } from "react"
import HoneypotField from "../../../../components/atoms/form/HoneypotField"
import FileInput from "../../../../components/molecules/form/FileInput"
import { PageContext } from "../../../../context/PageContext"
import useDatoMicrocopy from "../../../../hooks/useDatoMicrocopy"
import Fieldset from "../../../atoms/form/Fieldset"
import Input from "../../../atoms/form/Input"
import TextArea from "../../../atoms/form/TextArea"
// import * as styles from "./ProductSupportFeedbackFields.module.scss"

type ProductSupportFeedbackFieldsProps = { onFilesChange: (files: File[]) => void }

const ProductSupportFeedbackFields = (props: ProductSupportFeedbackFieldsProps) => {
  // console.log('Rendering ProductSupportFeedbackFields', props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("formContact", locale)

  return (
    <>
      <HoneypotField name="piilo-info" label="Info" />
      <Fieldset legend={t("support", "", "productSupportCategorySelect")}>
        <Input
          name="topic"
          label={t("legend.topic", "Topic")}
          required
          //errorMsg={getErrorMsg("productModel")}
        />
        <TextArea
          name="message"
          label={t("message.label", "Your message", "formCommon")}
          optionalText={t("optional", "optional", "formCommon")}
          required
        />
        <FileInput
          name="files"
          label={t("files.label", "Drag &amp; Drop your files here", "formCommon")}
          buttonLabel="Select files"
          onFilesChange={props.onFilesChange}
          previewTitle={t("files.preview.title", "Selected files", "formCommon")}
          previewRemoveLabel={t("files.preview.buttonLabel", "Remove", "formCommon")}
          optionalText={t("optional", "optional", "formCommon")}
          required={false}
          inputAttrs={{ accept: "image/*" }}
        />
      </Fieldset>
      <Fieldset legend={t("legend.contactInfo", "Your contact information")}>
        <Input
          name="email"
          label={t("email.label", "Email")}
          required
          placeholder={"user@example.com"}
          inputAttrs={{ type: "email", maxLength: 100 }}
          //errorMsg={getErrorMsg("email")}
        />
      </Fieldset>
    </>
  )
}

export default ProductSupportFeedbackFields
