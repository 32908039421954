import { ApiErrorDetails, ApiResponse, ValidationErrorType } from "functions/harvia-crm/types/api"
import { useState } from "react"

/** Azure Function App base URL */
const AZ_BASEURL = "https://func-app-harvia-crm.azurewebsites.net/api"

export type AzureEndpoint = "contact-b2b" | "contact-b2c" | "contact-product-support" | "heater-registration"

export type CrmError = {
  code: string
  message?: string
  fieldErrors: CrmFieldErrors
}

type FieldError = {
  type: ValidationErrorType
  message: string
}

type JSONPayload = Record<string, string>

export type CrmFieldErrors = Record<string, FieldError>

export type UploadState = {
  success: boolean
  file: File
}[]

type State<T> = {
  loading: boolean
  data: T | undefined
  error: CrmError | undefined
}

/**
 * Find error from `CrmFieldErrors` by field name and return associated human readable error message if any.
 * Helper function to keep logic involving CrmFieldErrors structure related details where its defined.
 */
export const fieldError = (errors: CrmFieldErrors | undefined, field: string): FieldError | undefined => {
  return errors && field in errors ? errors[field] : undefined
}

export const hasError = (error: CrmError | undefined): boolean => {
  if (error === undefined) {
    return false
  }

  // TODO CrmError structure may still change, confirm what is considered a error state and modify logic accordingly

  if (error.code) {
    // error.code should always be string if there is any error
    return true
  }

  // error.fieldErrors has form field specific errors
  // if (Object.entries(error.fieldErrors).length > 0) {
  //   return true
  // }

  return true
}

/**
 *
 * @param endpoint Azure function name in `func-app-harvia-crm` Function App
 */
function useCrmApi<T = Record<string, never>>(
  endpoint: AzureEndpoint
): readonly [
  State<T>,
  (data: FormData, files?: File[]) => void,
  () => void,
  (urlPath: string, token: string, files: File[]) => Promise<UploadState>
] {
  const [state, setState] = useState<State<T>>({
    loading: false,
    data: undefined,
    error: undefined,
  })

  /**
   * Invoke data submission to Harvia CRM via Azure Function
   */
  const submit = (data: FormData): void => {
    // console.debug("Form submit", data)

    const payload = formDataToJson(data)

    makeQuery(`${AZ_BASEURL}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
  }

  const uploadFiles = async (urlPath: string, token: string, files: File[]): Promise<UploadState> => {
    // console.debug(`Should upload files to ${urlPath} with Token ${token}. Files:`, files)
    const result: UploadState = []

    for (const file of files) {
      const data = new FormData()
      data.append("file", file)
      data.append("token", token)

      const res = await makeQuery(`${AZ_BASEURL}/${urlPath}`, {
        method: "POST",
        body: data,
      })
      if (res && res.success) {
        result.push({ success: true, file })
      } else {
        result.push({ success: false, file })
      }
    }

    return result
  }

  /**
   * Reset state (not form)
   */
  const reset = (): void => {
    setState({ ...state, loading: false, data: undefined, error: undefined })
  }

  /**
   * Convert harvoa-crm API error response (Joi validation error) details to
   * simpler structure more usefull for form UI components.
   *
   * @param details - Joi validation error details as retured from API
   * @returns Map whith field name as key and error info object as value
   */
  const parseValidationErrors = (details: ApiErrorDetails): CrmFieldErrors => {
    // console.debug("parsing validation errors from server response", details)
    const newErrors: CrmFieldErrors = {}
    details.reduce((newErrors, detail) => {
      const key = detail.context?.key
      if (key && !(key in newErrors)) {
        newErrors[key] = {
          type: detail.type,
          message: detail.message,
        }
      }
      return newErrors
    }, newErrors)
    // console.debug("new errors", newErrors)
    return newErrors
  }

  const apiFetch = async (url: string, request: RequestInit): Promise<ApiResponse<T>> => {
    // console.debug(`Submitting to ${url}`, request)
    const response = await window.fetch(url, request).catch(err => {
      console.error(err)
      const errorBody: ApiResponse<T> = { success: false, error: { code: "fetch", message: err.message } }
      return new Response(JSON.stringify(errorBody), { status: 400 })
    })

    let data
    try {
      data = await response.json()
    } catch (err) {
      console.error("useCrmApi(): Failed to parse response", response)
      return { success: false, error: { code: "crm-api", message: response.statusText } }
    }

    if (!response.ok) {
      console.error(`useCrmApi() response ${response.status} ${response.statusText}`, data)
      // just log, do not no return here. `data` is already of expected type and includes the real error payload
    }

    return data
  }

  const formDataToJson = (data: FormData): JSONPayload => {
    const payload: JSONPayload = {}
    for (const [key, value] of data.entries()) {
      if (value instanceof File) {
        data.delete(key)
      } else {
        payload[key] = value
      }
    }
    return payload
  }

  const makeQuery = async (url: string, request: RequestInit): Promise<ApiResponse<T> | undefined> => {
    if (state.loading) {
      console.warn("Skipping XHR because of ongoing request", state)
      return
    }
    if (state.error !== undefined) {
      // don't make new requests until state is cleared using reset()
      console.warn("Skipping XHR because of error state", state)
      return
    }

    // inform we're about to load some data
    setState({ ...state, loading: true })

    const result = await apiFetch(url, request)

    if (result.success === true) {
      setState({
        ...state,
        loading: false,
        error: undefined,
        data: result.data,
      })
    } else {
      console.warn("useCrmApi() error:", result)
      const { code, message, details } = result.error

      let fieldErrors: CrmFieldErrors = {}
      if (code === "validation" && details) {
        fieldErrors = parseValidationErrors(details)
      }

      setState({
        ...state,
        loading: false,
        error: { code, message, fieldErrors },
        data: undefined,
      })
    }
    return result
  }

  return [state, submit, reset, uploadFiles]
}

export default useCrmApi
