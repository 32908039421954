import React, { useContext } from "react"
import Fieldset from "../../../../components/atoms/form/Fieldset"
import RadioButton from "../../../../components/atoms/form/RadioButton"
import { PageContext } from "../../../../context/PageContext"
import useDatoMicrocopy from "../../../../hooks/useDatoMicrocopy"
import { DatoLocale } from "../../../../types"
import { OptionProps } from "../../../atoms/form/Option"

type ProductSupportCategoryRadioButtonsProps = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  locale: DatoLocale
  initialCategory?: string
}

/**
 * These must match keys in `productSupportCategoryRadioButtons` microcopy
 * https://harvia.admin.datocms.com/editor/item_types/1172670/items/89774267/edit
 */
const optValues = ["productFeedback", "productComplaint", "support", "spareParts"] as const

/**
 * Dropdown for contact forms. Renders a `<select name="category">` element.
 * Data is supposed to end up in Harvia CRM Ticket field `cf_1752`
 */
const ProductSupportCategoryRadioButtons: React.FC<ProductSupportCategoryRadioButtonsProps> = props => {
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("productSupportCategorySelect", locale)

  const options: OptionProps[] = optValues.map(opt => ({
    value: opt,
    label: t(opt, opt),
  }))

  return (
    <>
      <Fieldset legend={t("label", "What your messsage concerns")}>
        {options.map((opt, i) => (
          <div>
            <RadioButton
              name="category"
              defaultChecked={props.initialCategory === opt.value}
              label={opt.label}
              value={opt.value}
              required
              onChange={props.onChange}
            />
          </div>
        ))}
      </Fieldset>
    </>
  )
}

export default ProductSupportCategoryRadioButtons
